import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

const Header = function SchemaSection() {
  const [t] = useTranslation('common');
  return (
    <section id="platform-section" className="schema-system">
        <div className="section-heading text-center pt-5">
          <h2 className='section-title'>{t('spider-acoustic.schema.title')}</h2>
        </div>
        <img
            src= {t('spider-acoustic.schema.img')}
            alt="Componentes de plataforma SPIDER para alimentación de camarón"
        />
    </section>
  );
};

export default withTranslation('common')(Header);
