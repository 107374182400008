import React from "react";
import { withTranslation, useTranslation } from "react-i18next";

const Components = ({ data, id }) => {
  const [t] = useTranslation("common");
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" id={id}>
      <div className="spiderProduct-section--header">
        <h2>{t(`${data}.title`)}</h2>
        <div className="row components-title-items">
          <div className="col-md-12 text-center">
            <p className="d-flex mt-4">
              {t(`${data}.headingText`)} <br></br>
              {t(`${data}.headingTextTwo`)}
            </p>
          </div>
        </div>
      </div>
      <div className="spiderProduct-section--img">
        <img src={t(`${data}.img`)} alt={t(`${data}.img-alt`)} />
      </div>
    </div>
  );
};

export default withTranslation("common")(Components);
