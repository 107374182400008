import React from "react";
import Action from "../Action";
import TextSection from "../TextSection";
import { withTranslation, useTranslation } from "react-i18next";
import Carousel from "../../../ScreenshotSection/Carousel";


const Home = ({ reverse, id, data, action }) => {
  const [t] = useTranslation("common");
  return (
    <div className="spiderProduct-section" id={id}>
      <div style={{width:"100%"}} className={`row ${reverse ? "reverse" : ""}`}>
        <div className="d-flex justify-content-center flex-column align-items-center col-12 col-sm-12 col-md-7">
          <div
            style={{ width: "100%" }}
          >
            <div className="satty-home-img">
              <img style={{
                height: "100px",
                width: 'auto',
                padding: "1rem 0",
              }} src={t(`${data}.logo`)} alt="logo" />
            </div>
            <h1 className="satty-home-title">{t(`${data}.subtitle`)}</h1>
            <br />
          </div>
          <TextSection data={data} direction={"left"} />
          <div className="spiderProduct-section--footer">
            {action && <Action data={data} />}
          </div>
        </div>
        <div className="d-flex justify-content-end --img col-12 col-sm-12 col-md-5 py-4">
          <Carousel flag="feeder" data={t(`${data}.img`, { returnObjects: true })} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(Home);
