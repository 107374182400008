import React from "react";
import FeaturesList3 from "../FeaturesList3.js";
import { withTranslation, useTranslation } from "react-i18next";

const Features = ({ data }) => {
  const [t] = useTranslation("common");
  return (
    <div className="spiderProduct-section" id="Features">
      <div className="spiderProduct-section--data">
        <div>
          <h1 className="satty-features-title" >{t(`${data}.title`)}</h1>
          <div class="section-title-border"></div>
          <h3 className="satty-features-subtitle" >{t(`${data}.subtitle`)}</h3>
        </div>
        <FeaturesList3 data={data} />
      </div>
    </div>
  );
};

export default withTranslation("common")(Features);