import React from 'react';

import { withTranslation, useTranslation } from 'react-i18next';

const Header = function Home() {
  const [t] = useTranslation('common');
  return (
    <section
      id="spider_home"
      className="section welcome-area overflow-hidden d-flex align-items-center mt-5 pt-5"
    >
      <div className="container">
        <div className="column align-items-center justify-content-center">
          <div className="col-md-12 col-lg-12">
            <div className="principal-title">{t("spider-acoustic.home.title")}</div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="principal-subtitle">{t("spider-acoustic.home.text")}</div>
          </div>
        </div>
      </div>
      {/* Shape Bottom */}
      <div className="shape-bottom">
        <svg
          fill="#f6f9fe"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path className="fill" d="M0,6V0h1000v100L0,6z" />
        </svg>
      </div>
    </section>
  );
};

export default withTranslation('common')(Header);
