import React from "react";

import "./css/Styles.css";
import "./css/Home.css";
import "./css/Features.css";
import "./css/satty.css";


//import Home from "./components/Home";
import Home from "./components/Satty/SattyHome";
import ImageBackground from "./components/Satty/ImageBackground";
import Features2 from "./components/Satty/SattyFeatures";
import DefaultScreen from "./components/Satty/SattyScreen";
import DefaultScreen2 from "./components/Satty/SattyScreen2";
import { withTranslation } from "react-i18next";
import FooterSection from "./components/Satty/FooterSection";
import ContactSection from "../ContactSection/Contact";

const data = `satty`;

const Satty = () => {
  return (
    <div className="mt-5 pt-5 spiderProduct-Screen" id="satty">
      <Home id={"satty"} reverse={false}  data={`${data}.home`} action={false} />
      <Features2 data={`${data}.elements`} />
      <ImageBackground />
      <Features2 data={`${data}.features`} />

      <DefaultScreen
        reverse={false}
        data={`${data}.parameters`}
        id={"satty-Parameters"}
      />
      <DefaultScreen
        reverse={true}
        data={`${data}.threats`}
        id={"satty-Threats"}
      />
      <DefaultScreen
        reverse={false}
        data={`${data}.sounder`}
        id={"sattySounder"}
      />
      <DefaultScreen
        reverse={true}
        data={`${data}.risk`}
        id={"sattyRisk"}
      />
      <DefaultScreen2
        reverse={false}
        data={`${data}.services`}
        id={"digitalServices"}
      />
      <DefaultScreen2 id={"sattyAPI"} reverse={true} data={`${data}.api`} />
      <DefaultScreen2 id={"sattyCloud"} reverse={false} data={`${data}.cloud`} />
      <DefaultScreen2
        reverse={true}
        data={`${data}.app`}
        id={"sattyApp"}
      />
      <Features2 data={`${data}.benefits`} />
      <DefaultScreen2
        reverse={false}
        data={`${data}.tech`}
        id={"tech"}
      />
      <FooterSection />
      <ContactSection color={"#8b03cb"} />

    </div>
  );
};

export default withTranslation("common")(Satty);
