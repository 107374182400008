import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import ImageSection from "../ImageSection";

const Features = ({ data }) => {
  const [t] = useTranslation("common");
  return (
    <div id="xAgro-features-platform">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="row">
          <div className="col-12 col-md-7 d-flex flex-column">
            <div>
              <h1 className="xAgro-features-platform-title">{t(`${data}.title`)}</h1>
            </div>
            <div>
              <h3 className="xAgro-features-platform-text"> 
              {t(`${data}.text`)}
              </h3>
            </div>
            <div className="row xAgro-features-platform-container">
              <div className="col-md-6 mt-3">
                <h4>
                  {t(`${data}.feature_1_title`)}
                </h4>
                <span>
                  {t(`${data}.feature_1_content`)}
                </span>
              </div>
              <div className="col-md-6 mt-3">
                <h4>
                  {t(`${data}.feature_2_title`)}
                </h4>
                <span>
                  {t(`${data}.feature_2_content`)}
                </span>
              </div>
              <div className="col-md-6 mt-3">
                <h4>
                  {t(`${data}.feature_3_title`)}
                </h4>
                <span>
                  {t(`${data}.feature_3_content`)}
                </span>
              </div>
              <div className="col-md-6 mt-3">
                <h4>
                  {t(`${data}.feature_4_title`)}
                </h4>
                <span>
                  {t(`${data}.feature_4_content`)}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 d-flex align-items-center">
            <div className='pt-1'>
              <ImageSection data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(Features);
