import React from "react";
import ImageSection from "../ImageSection";
import { withTranslation, useTranslation } from "react-i18next";

const DefaultScreen = ({ reverse, data, id }) => {
  const [t] = useTranslation("common");
  const textAlign = reverse ? "right" : "left";
  const containerStyle = {
    textAlign
  };

  return (
    <div className="xAgro-digital-tools-section d-flex justify-content-center align-content-center" id={id}>
      <div className={`row ${reverse ? "reverse" : ""}`} style={{width:'100%',margin:'0px'}}>
        <div className="d-flex flex-column justify-content-center align-items-center col-12 col-sm-12 col-md-6">
          <div>
            <h1 className="xAgro-digital-tools-title"> {t(`${data}.title`)} </h1>
          </div>
          <div>
            <h3 className="xAgro-digital-tools-subtitle" > {t(`${data}.subtitle`)} </h3>
          </div>
          <div className="row xAgro-digital-tools-container">
            <div className="col-md-12 mt-3">
              <h4>
                {t(`${data}.feature_1_title`)}
              </h4>
              <span>
                {t(`${data}.feature_1_content`)}
              </span>
            </div>
            <div className="col-md-12 mt-3">
              <h4>
                {t(`${data}.feature_2_title`)}
              </h4>
              <span>
                {t(`${data}.feature_2_content`)}
              </span>
            </div>
            <div className="col-md-12 mt-3">
              <h4>
                {t(`${data}.feature_3_title`)}
              </h4>
              <span>
                {t(`${data}.feature_3_content`)}
              </span>
            </div>
            <div className="col-md-12 mt-3">
              <h4>
                {t(`${data}.feature_4_title`)}
              </h4>
              <span>
                {t(`${data}.feature_4_content`)}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center col-12 col-sm-12 col-md-6">
          <ImageSection data={data} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(DefaultScreen);