import React from "react";
import FeaturesList2 from "./FeaturesList2";
import { withTranslation, useTranslation } from "react-i18next";

const Features = ({ data }) => {
  const [t] = useTranslation("common");
  return (
    <div className="spiderProduct-section" id="Features">
      <div className="spiderProduct-section--data">
        <div className="spiderProduct-section--header">
          <h1>{t(`${data}.subtitle`)}</h1>
          <div className="section-title-border"></div>
        </div>
        <FeaturesList2 data={data} />
      </div>
    </div>
  );
};

export default withTranslation("common")(Features);
