import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

const FeatureSection = function Comp({ data }) {
  const [t] = useTranslation('common');

  return (
    <section id="xAgroResume" className="section xAgro-resume-section bg-footer">
      <div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="section-heading-white text-center">
              <h1 className='xAgro-resume-title'>{t(`${data}.title`)}</h1>
              <p className="d-none d-sm-block mt-2 xAgro-resume-subtitle">{t(`${data}.text`)}</p>
              <p className="d-block d-sm-none mt-2 xAgro-resume-subtitle">{t(`${data}.text`)}</p>
            </div>
          </div>
        </div>
        <div className="row align-items-center xAgro-resume-container">
          { t(`${data}.data`,{ returnObjects: true }).map((item_array, idx_array) => {
            return (
              <div key={`gain_${idx_array}`} className="col-12 col-md-4">
                {/* Icon Box */}
                <div className="single-work2 text-center p-0">
                  {/* Icon Text */}
                  <div className="icon-text">
                    <h3 className="options-title-white mb-2"> {t(`${item_array.title}`)} </h3>
                    {/* Featured Icon */}
                    <div className='d-flex align-items-center justify-content-center'>
                      <div
                        className="svg-feature d-flex justify-content-center"
                        style={{
                          background: `url('${item_array.svg}') center no-repeat`,
                        }}
                      >
                      </div>
                    </div>
                    <p className='options-content-white'>{t(`${item_array.text}`)}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default withTranslation('common')(FeatureSection);
