import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const CarouselGalery = ({ flag,data }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel
      indicators={false}
      slide={false}
      controls={false}
      className="carousel"
      activeIndex={index}
      onSelect={handleSelect}
    >
      {data.map((item, idx) => (
        <Carousel.Item key={`${flag}_${idx}`} className="carousel-item" interval={2500}>
          <img
            key={`${flag}_${idx}`}
            style={{ height: "500px"}}
            className="d-block w-100"
            src={item.src}
            alt={item.alt}
          />
          <Carousel.Caption>
            <h3>{item.subtitle}</h3>
            <p>{item.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselGalery;
