import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

// const initData = {
//   data: {
//     heading: 'Preguntas frecuentes',
//   },
//   faqData: [
//     {
//       title: '¿Por qué cambiar mis hábitos producción?',
//       content:
//         'Incluso una pequeña mejora en el proceso de producción se traduce en mayores ganancias y ahorro. BioDynamics ataca ambos frentes, reduce el factor de conversión entre 0.1 y 0.3 con la alimentación acúticas, aprende a detectar problemas con equipos y determinar  los tiempos óptimos de uso de equipos y de cosecha.',
//     },
//     {
//       title: '¿Puedo usar esta tecnología un pequeño y mediano productor?',
//       content:
//         'Si, Biodynamics provee un sistema autocontenido, es decir no es necesario tener sistemas de control, ni infraestructura de telecomunicaciones, o incluso energía eléctrica. Nuestros sistemas operan con energía solar y forman una red de comunicaciones entre ellos.',
//     },
//     {
//       title: '¿Si algo pasa debo esperar por soporte del extranjero?',
//       content:
//         'No, Biodynamics es fabricante de toda la tecnología hardware y software. No somos representantes de terceros. Así que el asesoramiento, mantenimiento y soporte es directo.',
//     },
//     {
//       title: '¿Debo incrementar el personal al usar sus productos?',
//       content:
//         'No, BioDynamics incluye un paquete de capacitación al personal de tu empresa para operar y dar soporte y mantenimiento de primera línea. Es una oportunidad para diversificar el trabajo de los operarios actuales y que los administradores adquieran destrezas en análisis de datos.',
//     },
//     {
//       title: '¿Necesito internet o computadoras en el campamento?',
//       content:
//         'No, las operaciones de BioDynamics se manejan sin necesidad de un computador de escritorio. La configuración y operación se puede hacer desde un teléfono móvil o tablet.',
//     },
//     {
//       title: '¿Cómo puedo saber que productos necesito?',
//       content:
//         'Contactanos, tenemos diferentes planes dependiendo del tamaño de tu operación, la propiedad de los equipos y las funcionalidades que desees habilitar. Si es necesario hacer ingeniería para conectarnos a sistemas existentes eso también lo podemos hacer.',
//     },
//   ],
// };

const FaqSection = function Comp() {
  const [t] = useTranslation('common');

  return (
    <section id="faq" className="section faq-area style-two ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-7">
            {/* Section Heading */}
            <div className="section-heading text-center">
              <h2 className="section-title text-capitalize">{t('faq.title')}</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            {/* FAQ Content */}
            <div className="faq-content">
              {/* sApp Accordion */}
              <div className="accordion" id="sApp-accordion">
                <div className="row justify-content-center" >
                  {/* Single Card */}
                  {
                  t('faq.data',{ returnObjects: true }).map((question, idx) => {
                    return (
                      <div
                        key={`fo_${idx}`}
                        className="card border-0 col-12 col-md-6"
                        style={{ 'background':'#f8faff'}}
                      >
                        {/* Card Header */}
                        <div className="card-header bg-inherit border-0 p-0">
                          <h4 className="mb-0">
                            {t(`${question.title}`)}
                          </h4>
                        </div>
                        {/* Card Body */}
                        <div className="card-body px-0 py-3">
                          {t(`${question.text}`)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation('common')(FaqSection);
