import React from "react";
import { withTranslation, useTranslation } from "react-i18next";

const FeaturesList = ({ data }) => { 
  const [t] = useTranslation("common");
    return (
      <ul>
        {t(`${data}.features`, { returnObjects: true }).map(
          (feature, index) => {
            return <li key={`${data}.features_${index}`}>{feature}</li>;
          }
        )}
      </ul>
    );
};

export default withTranslation("common")(FeaturesList);

