import React from 'react';
import { withTranslation, useTranslation } from "react-i18next";


const Header = function HomeFeaturesSection(){
    const [t] = useTranslation("common");
    return (
      <section
        id="feature-section"
        className="section discover-area bg-gray overflow-hidden mt-5 pt-5 mb-5 pb-5"
      >
        <div className="section-heading text-center pt-5">
          <h2 className="section-title">{t("home_features.title")}</h2>
        </div>
        <div className="description-main-container">
          <div className="row">
            <div className="description-container col-12 col-md-12">
              <div className="description-content col-12 col-md-12 justify-content-center">
                {t("home_features.text")}
              </div>
              <div className="description-list-features col-12 col-md-12">
                <div className="row">
                  {t("home_features.data", { returnObjects: true }).map(
                    (feature, idx) => (
                      <div
                        key={`feature_${idx}`}
                        className="description-feature col-12 col-md-6 d-flex flex-row justify-content-center align-items-center"
                      >
                        <div className="description-item-img col-4 col-md-2 col-lg-2 d-flex justify-content-center align-items-center">
                          <div
                            className="svg-feature"
                            style={{
                              background: `url('${feature.img}') center no-repeat`,
                            }}
                          ></div>
                        </div>
                        <div className="description-item-container d-flex flex-column col-8 col-md-10 col-lg-10">
                          <div className="description-item-title">
                            {t(`${feature.title}`)}
                          </div>
                          <div className="description-item-content">
                            {t(`${feature.content}`)}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default withTranslation("common")(Header);