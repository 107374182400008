import React from "react";

import "./css/Styles.css";
import "./css/Home.css";
import "./css/Features.css";
import "./css/FeederManager.css";

import Home from "./components/FeederHome";
import Components from "./components/Components";
import Features from "./components/Features";
import DefaultScreen from "./components/DefaultScreen";
import { withTranslation } from "react-i18next";
import CarouselScreen from "./components/CarouselScreen";
import ContactSection from "../ContactSection/Contact";

const data = `feeder-manager`;

const SpiderFeeder = () => {
  return (
    <div className="mt-5 pt-2 spiderProduct-Screen" id="spiderFeeder">
      <Home id={"Feeder"} reverse={false}  data={`${data}.home`} action={false} />
      <Features className="pt-5 pb-5" data={`${data}.features`} />
      {/* 
      <Components data={`${data}.components`} id={"Components"} />*/}
      <DefaultScreen
        reverse={false}
        data={`${data}.finca-tech`}
        id={"Finca-tech"}
      />
      <Features data={`${data}.features-other`} />
      <CarouselScreen
        reverse={true}
        data={`${data}.feeder-manager-carousel`}
        id={"screenshots"}
      />
      <Features data={`${data}.benefits`} />
      <ContactSection color={"#3d245f"} />

    </div>
  );
};

export default withTranslation("common")(SpiderFeeder);