import React from "react";
import TextSection from "../TextSection";
import ImageSection from "../ImageSection";
import { withTranslation, useTranslation } from "react-i18next";

const DefaultScreen = ({ reverse, data, id }) => {
  const [t] = useTranslation("common");
  const textAlign = reverse ? "right" : "left";
  const containerStyle = {
    textAlign
  };

  return (
    <div className="xAgro-screen-section d-flex flex-column justify-content-center align-items-cente" id={id}>
      <div className={`row ${reverse ? "reverse" : ""}`} style={{width:'100%',margin:'0px'}}>
        <div className="d-flex align-items-center col-12 col-sm-12 col-md-7">
          <div style={containerStyle}>
            <div>
              <h3 className="xAgro-screen-subtitle">{t(`${data}.subtitle`)}</h3>
              <h2 className="xAgro-screen-title"s tyle={{ textAlign }}>{t(`${data}.title`)}</h2>
            </div>
            <TextSection className="mt-2" data={data} textAlign={textAlign} />
          </div>
        </div>
        <div className="d-flex justify-content-center col-12 col-sm-12 col-md-5">
          <ImageSection data={data} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(DefaultScreen);