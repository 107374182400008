
import "./css/SpiderAcoustic.css";

import React from "react";
import Home from "./SpiderAcoustic/Home";
import FeaturesSection from "./SpiderAcoustic/FeaturesSection";
import Work from "./SpiderAcoustic/Work";
import SchemaSection from "./SpiderAcoustic/SchemaSection";
import PlatformElementsSection from "./SpiderAcoustic/PlatformElementsSection";
import Gains from "./SpiderAcoustic/Gains";
import AcousticSection from "./SpiderAcoustic/AcousticSection";
//import PricingSection from "../PricingSection/PricingSpider";
import FaqSection from "../FaqSection/FaqOne";
import DefaultScreen from "./components/DefaultScreen";
import ContactSection from "../ContactSection/Contact";

import { withTranslation } from "react-i18next";

const SpiderAcoustic = () => {
  return (
    <div className="homepage-6">
      <div className="main">
        <Home />
        <FeaturesSection />
        <Work></Work>
        <SchemaSection />
        <PlatformElementsSection />
        <AcousticSection />
        <DefaultScreen
          reverse={false}
          data={"spider-acoustic.ml"}
          id={"acoustic-ml"}
        />
        <Gains />
        <DefaultScreen
          reverse={true}
          data={"spider-acoustic.hardware"}
          id={"acoustic-hardware"}
        />
        <DefaultScreen
          reverse={false}
          data={"spider-acoustic.rules"}
          id={"acoustic-feeder"}
        />
        <DefaultScreen
          reverse={true}
          data={"spider-acoustic.old_feeder"}
          id={"acoustic-old_feeder"}
        />
        <FaqSection />
        <ContactSection color={"#df2a2d"} />

      </div>
    </div>
  );
};

export default withTranslation("common")(SpiderAcoustic);
