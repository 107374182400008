import React from "react";
import TextSection from "./TextSection";
import ImageSection from "./ImageSection";
import { withTranslation, useTranslation } from "react-i18next";

const DefaultScreen = ({ reverse, data, id }) => {
  const [t] = useTranslation("common");
  return (
    <div className="spiderProduct-section" id={id}>
      <div className={`row ${reverse ? "reverse" : ""}`}>
        <div className="spiderProduct-section--data col-12 col-sm-12 col-md-7">
          <div className="spiderProduct-section--header">
            <h2> {t(`${data}.subtitle`)} </h2>
          </div>
          <TextSection data={`${data}`} textAlign={"center"} />
        </div>
        <div className="d-flex justify-content-center col-12 col-sm-12 col-md-5">
          <ImageSection data={data} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(DefaultScreen);
