import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";

// importing all the themes
/*
import ThemeOne from '../themes/theme-one';
import ThemeTwo from '../themes/theme-two';
import ThemeThree from '../themes/theme-three';
import ThemeFour from '../themes/theme-four';
import BlogTwoColumn from '../components/Blogs/BlogTwoColumn';
import BlogThreeColumn from '../components/Blogs/BlogThreeColumn';
import BlogLeftSidebar from '../components/Blogs/BlogLeftSidebar';
import BlogRightSidebar from '../components/Blogs/BlogRightSidebar';
import BlogDetailsLeftSidebar from '../components/Blogs/BlogDetailsLeftSidebar';
import BlogDetailsRightSidebar from '../components/Blogs/BlogDetailsRightSidebar';
import Reviews from '../components/ReviewSection/Reviews';
import Pricing from '../components/PricingSection/Pricing';
import DownloadPage from '../components/DownloadSection/DownloadPage';
import SubscribePage from '../components/SubscribeSection/SubscribePage';
import ThankYou from '../components/InnerSection/ThankYou';
import ComingSoon from '../components/InnerSection/ComingSoon';
import Login from '../components/Accounts/Login';
import Signup from '../components/Accounts/Signup';
import Forgot from '../components/Accounts/Forgot';
import Faq from '../components/FaqSection/Faq';
import ErrorPage from '../components/ErrorPage/404';
import ContactPage from '../components/ContactSection/ContactPage'; */

import Header from "../components/HeaderSection/Header";
import ThemeSix from "../themes/theme-six";
import { WhatsappBubble } from "../components/Bubbles/WhatsappBubble";
import { ScrollToTopBubble } from "../components/Bubbles/ScrollToTopBubble";
import SpiderMonitor from "../components/Products/SpiderMonitor.js";
import SpiderFeeder from "../components/Products/AquaFeeder.js";
import FeederManager from "../components/Products/FeederManager.js";
import SpiderAcoustic from "../components/Products/SpiderAcoustic";
import FooterSection from "../components/FooterSection/Footer";
import Satty from "../components/Products/Satty";
import XAgro from "../components/Products/xAgro.js";

import LanguageSelector from "../components/HeaderSection/Header";

class MyRouts extends React.Component {
  render() {
    return (
      <div id="routes">
        <Router>
          <ScrollToTopBubble />
          <WhatsappBubble number="+593959513557" />
          <LanguageSelector />
          <Header imageData={"/assets/img/logo.svg"} />
          <Switch>
            <Route exact path="/" component={ThemeSix} />
            <Route exact path="/spider" component={SpiderAcoustic} />
            <Route exact path="/feeder_manager" component={FeederManager} />
            {/** 
            <Route exact path="/spider/monitor" component={SpiderMonitor} />
            <Route exact path="/feeder" component={SpiderFeeder} />*/}
            <Route exact path="/satty" component={Satty} />
            <Route exact path="/xAgro" component={XAgro} />
            <Redirect to="/" />
          </Switch>
          
          <FooterSection imageData={"/assets/img/logo.svg"} />
        </Router>
      </div>
    );
  }
}
export default MyRouts;
