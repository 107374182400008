import React from 'react';
import { useHistory } from 'react-router-dom';

import { withTranslation, useTranslation } from 'react-i18next';

const FooterSection = () => {
  const [t] = useTranslation("common");

  const footerText = t('xAgro.footer.text', { returnObjects: true });

  const combinedText = footerText.map(item => {
    if (item.type === 'strong') {
      return <strong key={item.content}>{item.content}</strong>;
    } else {
      return item.content;
    }
  });
  let navigate = useHistory(); 
  const routeChange = () =>{ 
    let path = `#contact`; 
    navigate.push(path);
  }
  
  return (
    <section 
       id="xAgroFooter"
      className="section overflow-hidden ptb_100 d-flex align-items-center justify-content-end flex-column">
      <div className='row'>
        <div className='col-md-12'>
          <h2 className='xAgro-footer-text mx-5 text-center'>{combinedText}</h2>
        </div>
        {/*
        <div className='col-md-12 d-flex justify-content-center mt-6'>
          <button className='xAgro-footer-btn btn btn-lg btn-block px-2'
           onClick={routeChange}
          >COTIZA</button>
        </div>*/}
      </div>
      
    </section>
  );
};

export default withTranslation('common')(FooterSection);

