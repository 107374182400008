import React from "react";
import { withTranslation, useTranslation } from "react-i18next";

const Header = function AcousticSection() {
  const [t] = useTranslation("common");

  return (
    <section
      id="acoustic-section"
      className="section discover-area overflow-hidden mt-5 pt-5"
    >
      <div className="section-heading text-center pt-5 bg-gray">
        <h2 className="section-title">{t("spider-acoustic.acoustic.title")}</h2>
      </div>
      <div className="container ">
        <div className="row justify-content-between">
          {t("spider-acoustic.acoustic.data", { returnObjects: true }).map((x, idx) => (
            <div
              className="col-12 col-md-4 feature-acoustic-container"
              key={idx}
            >
              <div className="feature-acoustic-title">{t(`${x.title}`)}</div>
              <div className="feature-acoustic-img">
                <img
                  src={x.img}
                  width={300}
                  height={300}
                  alt="inteligencia artificial sobre el agua, algoritmos apetito mediante acústica"
                />
              </div>
              <div className="feature-acoustic-content align-self-center">
                {t(`${x.text}`)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default withTranslation("common")(Header);
