import React from "react";

import "./css/Styles.css";
import "./css/Home.css";
import "./css/Features.css";

import Home from "./components/Home";
import Features from "./components/Features";
import Components from "./components/Components";
import DefaultScreen from "./components/DefaultScreen";

import { withTranslation } from "react-i18next";

const SpiderMonitor = () => {
  return (
    <div className="spiderProduct-Screen main" id="spiderMonitor">
      <Home id={"Home"} reverse={false} data={"spider-monitor.home"} action={true} />
      <Components data={"spider-monitor.components"} id={"Components"} />
      <Features data={"spider-monitor.features"} />
      <DefaultScreen
        reverse={false}
        data={"spider-monitor.water-monitoring"}
        id={"MonitoreoAgua"}
      />
      <DefaultScreen
        reverse={true}
        data={"spider-monitor.alerts"}
        id={"Alerts"}
      />
      <DefaultScreen
        reverse={false}
        data={"spider-monitor.pool-monitoring"}
        id={"MonitreoPiscinas"}
      />
      <DefaultScreen
        reverse={true}
        data={"spider-monitor.center"}
        id={"Center"}
      />
      <DefaultScreen
        reverse={false}
        data={"spider-monitor.compatible-sensors"}
        id={"SensoresCompatibles"}
      />
    </div>
  );
};

export default withTranslation("common")(SpiderMonitor);
