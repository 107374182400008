import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { init } from "./ga";
import * as serviceWorker from "./serviceWorker";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import "./i18n";

const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: '30px',
    transition: transitions.SCALE
};

(async () => {
    
    await init();
    ReactDOM.render(
        <React.StrictMode>
            <AlertProvider template={AlertTemplate} {...options}>
                < App />
            </AlertProvider>
        </React.StrictMode>        
    , document.getElementById("root"));
})();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
