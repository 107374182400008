import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

const initData = {
  image: '/assets/img/logo.svg',
  text:
    'BioDynamics es la plataforma tecnológica basada en Inteligencia Artificial para optimizar las operaciones de la industria acuícola.',
  linkText_1: 'Links útiles',
  iconList: [
    {
      id: 1,
      name: 'twitter',
      src: '/assets/icons/twitter.svg',
      href: 'https://twitter.com/BioDynamicsTech',
    },
    {
      id: 2,
      name: 'linkedin',
      src: '/assets/icons/linkedin.svg',
      href:
        'https://www.linkedin.com/company/biodynamicstech/?viewAsMember=true',
    },
    {
      id: 3,
      name: 'linkedin',
      src: '/assets/icons/instagram.svg',
      href:
        'https://www.instagram.com/biodynamics_tech/?hl=es',
    }
  ],
  footerList_1: [
    {
      id: 1,
      text: 'Inicio',
      link: '#home',
    },
    {
      id: 2,
      text: '¿Qué es?',
      link: '#feature-section',
    },
    {
      id: 3,
      text: 'Características',
      link: '#acoustic-section',
    },
    {
      id: 4,
      text: '¿Còmo funciona?',
      link: '#pricing',
    },
    {
      id: 5,
      text: 'Faq',
      link: '#faq',
    },
    {
      id: 6,
      text: 'Contacto',
      link: '#contact',
    },
  ],
};

const FooterSection = function Comp(props) {
  const [t] = useTranslation('common');

  return (
    <div>
      <div className="height-emulator d-none d-lg-block" />
      <footer className="footer-area footer-fixed">
        {/* Footer Top */}
        <div className="footer-top ptb_100">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-12 col-sm-5 col-lg-5">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Logo */}
                  <a className="navbar-brand" href="/#">
                    <img
                      className="footer-brand"
                      src={props.imageData}
                      alt="Logo del Biodynamics"
                    />
                  </a>
                  <p className="mt-2 mb-3">{t('footer.text')}</p>
                  {/* Social Icons */}
                  <div className="social-icons d-flex">
                    {initData.iconList.map((item, idx) => {
                      return (
                        <a
                          className="p-1"
                          key={`fi_${idx}`}
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={item.src} alt={item.name} />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-5 col-lg-5">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Footer Title */}
                  <h3 className="footer-title mb-2 py-2">
                    {t('footer.linkText')}
                  </h3>
                  <div className="row">
                    {t("footer.links", { returnObjects: true }).map((item, idx) => {
                      return (
                        <div key={`flo_${idx}`} className="align-self-center py-0 col-12 col-lg-6">
                          <a className="nav-link scroll" href={item.href}>
                            {item.name}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* Copyright Area */}
                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                  {/* Copyright Left */}
                  <div className="copyright-left">
                    © Copyrights 2020 Biodynamics All rights reserved.
                  </div>
                  {/* Copyright Right */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default withTranslation('common')(FooterSection);
