import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

const data = [
  {
    id: '1',
    image: 'assets/img/brand_thumb_1.png',
  },
  {
    id: '2',
    image: 'assets/img/brand_thumb_2.png',
  },
  {
    id: '3',
    image: 'assets/img/brand_thumb_3.png',
  },
];

const BrandingSection = function Comp() {
  const [t] = useTranslation('common');

  return (
    <section className="branding-area ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            {/* Section Heading */}
            <div className="section-heading text-center">
              <h2 className='section-title' >{t('brand.title')}</h2>
              <p className="options-content d-none d-sm-block mt-4">{t('brand.text')}</p>
              <p className="options-content d-block d-sm-none mt-4">{t('brand.text')}</p>
            </div>
          </div>
        </div>
        <div className="row ">
          {/* Branding Slider */}

          {/* Single Brand */}
          {data.map((item, idx) => {
            return (
              <div
                key={`d_${idx}`}
                className="p-5 col-12 col-md-4 align-self-center brand-img"
              >
                <img src={item.image} alt="" />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default withTranslation('common')(BrandingSection);
