import React from "react";

const FeaturesList2 = ({ data, index1, feature }) => {
  return (
    <div
      className="spiderProduct-section--main"
      style={{
        textAlign: "center",
      }}
    >
      {feature["text"].map((text, index2) => {
        if (text.type === "strong") {
          return (
            <strong
              style={{
                display: "inline",
              }}
              key={`${data}.features-list_${index1}_${index2}`}
            >
              {text.content}{" "}
            </strong>
          );
        } else if (text.type === "normal") {
          return (
            <p
              style={{
                display: "inline",
              }}
              key={`${data}.features-list_${index1}_${index2}`}
            >
              {text.content}{" "}
            </p>
          );
        } else if (text.type === "underline") {
          return (
            <p
              style={{
                display: "inline",
                textDecoration: "underline",
              }}
              key={`${data}.features-list_${index1}_${index2}`}
            >
              {text.content}{" "}
            </p>
          );
        } else {
          return <p>{text.content}</p>;
        }
      })}
    </div>
  );
};

export default FeaturesList2;
