import React from 'react';

import { withTranslation, useTranslation } from 'react-i18next';

const ImageTextComponent = () => {
  const [t] = useTranslation('common');
  return (
    <div className="image-text-container" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <img style={{padding:'2rem'}} src="assets/img/satty/satty_logo.png" alt=""/>
        <div className="satty-section--footer" >
          {t("satty.footer.text")}
        </div>
      <img style={{padding:'2rem'}} src="assets/img/satty/sentify.png" alt=""/>
        <div className="satty-section--footer" >
          {t("satty.footer.text2")}
        </div>
      <img style={{padding:'2rem', height:'300px'}} src="assets/img/satty/espoltel.png" alt=""/>
    </div>
  );
};

export default ImageTextComponent;
