import React from 'react';
import {  useEffect } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAlert } from 'react-alert';

const Spinner = function Spinner() {
  return (
    <span
      className="spinner-grow spinner-grow-sm mr-3"
      role="status"
      aria-hidden="true"
    ></span>
  );
};

const Send = function Send() {
  return (
    <span className="text-white pr-3">
      <i className="fas fa-paper-plane" />
    </span>
  );
};

const ContactForm = function ContactForm(props) {
  const [t] = useTranslation('common');
  const alert = useAlert();
  const all_options = [
    {
      "name" : "monitor",
      "value" : t('contact.water_monitoring')
    },
    {
      "name" : "weather",
      "value" :  t('contact.weather_stations')
    },
    {
      "name" : "feeders",
      "value" :  t('contact.feeders')
    },
    {
      "name" : "old_feeders",
      "value" : t('contact.old_feeders')
    },
    {
      "name" : "acoustic",
      "value" : t('contact.acoustic')
    },
    {
      "name" : "monitor",
      "value" : t('contact.others')
    },
  ];
  let label = t('contact.label');
  let plHolder = {
    name: t('contact.form.name'),
    last_name : t('contact.form.last_name'),
    job : t('contact.form.job'),
    email: t('contact.form.email'),
    phone : t('contact.form.phone'),
    msg: t('contact.form.msg'),
    sent: t('contact.form.sent'),
    button: t('contact.form.button'),
  };
  let myForm = React.createRef();
  let [sending, setSending] = React.useState(false);
  const [isdisabled, setIsDisabled] = React.useState(true);
  let [state, setState] = React.useState({
    name: '',
    last_name : 'no_last_name',
    job: 'lead',
    email: '',
    phone : '123456789',
    message: '',
    str_options : 'no_data',
    options : new Array(all_options.length).fill(false)
  });
  useEffect(() => {    
    validateHandler(); 
  });
  const changHandler = (event) => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));

  };

  const validateHandler = () => {
    if( state.name !== "" 
      && state.last_name !== "" 
      && state.job !== ""
      && state.email !== ""
      && state.phone > 0 
      && state.message !== ""
      && state.str_options !== ""
    ){
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  const handleOnChange = (position) => {
    const updatedCheckedState = state.options.map((item, index) =>
      index === position ? !item : item
    );
    setState(state => ({
      ...state,
      ["options"] : updatedCheckedState,
      ["str_options"] : convert_options_to_str(updatedCheckedState)
    }));
    validateHandler();
  };

  const convert_options_to_str = (flags) => {
    let option_str = "";
    flags.map((x,index)=>{
      if(x){
        option_str += all_options[index].name
        option_str += "\t"
      }
    });
    return option_str;
  }

  const submitHangler =  async(event) => {
    //event.preventDefault();
    
    const sleep = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const funtionCall = async () => {
      validateHandler();
      if(!(isdisabled)){
        setSending(true);
        try{
          myForm.current.reset();
          setState({
            name: '',
            last_name : 'no_last_name',
            job: 'lead',
            email: '',
            phone : '123456789',
            message: '',
            str_options : 'no_data',
            options : new Array(all_options.length).fill(false)
          });
          await axios({
            method: 'POST',
            url: 'https://biodynamics.tech/api/email/send',
            data: state,
            headers: null,
          });
          await sleep(3000);
          setSending(false);
          alert.show('Formulario enviado!')
          
        }catch(e){
          alert.show("Error enviando formulario! Intentelo mas tarde");
          setSending(false);
        }
      }else{
        setSending(false);
      }
    };
    funtionCall();
  };

  return (
    <div className="contact-box text-center">
      <form ref={myForm} onSubmit={submitHangler} className="contact-form">
        <div className="row contact-form-container">
          <div className='col-12'>
            <div className="form-group">
              <input 
                type="text" className="form-control" name="name" placeholder={plHolder.name} required="required" onChange={changHandler}
              />
            </div>
          </div>
          <div className='col-6'>
            <div className="form-group">
              <input hidden
                type="text" className="form-control" name="last_name" placeholder={plHolder.last_name} onChange={changHandler}
              />
            </div>
          </div>
          {
          <div className="col-12">
            <div className="form-group">
              <input hidden 
                type="text" className="form-control" name="job" placeholder={plHolder.job} onChange={changHandler}
              />
            </div>
          </div>}
          <div className='col-12'>
            <div className="form-group">
              <input
                type="email" className="form-control" name="email" placeholder={plHolder.email} required="required" onChange={changHandler}
              />
            </div>
          </div>
          { 
          <div className='col-6'>
            <div className="form-group">
              <input hidden 
                type="number" className="form-control" name="phone" placeholder={plHolder.phone} onChange={changHandler}
              />
            </div>
          </div>}
          {/*
          <div className='col-12 col-md-6'>
            <p className='contact-list-label col-12 '> {label} </p>
            {all_options.map(( { name, value } , index) => {
            return(
              <div className="form-group" key={`key_${index}`} >
                <li  className="d-flex align-items-center" >
                    <input hidden
                      className='contact-checkbox'
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name={name}
                      value={name}
                      checked={state.options[index]}
                      onChange={() => handleOnChange(index)}
                    />
                    <label className='contact-label' htmlFor={`custom-checkbox-${index}`}>{value}</label>
                </li>
              </div>
            );
          })
          }
          </div>
          */}
          <div className="col-12 col-md-12">
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                placeholder={plHolder.msg}
                required="required"
                onChange={changHandler}
                value={state.message}
              />
            </div>
          </div>

          <div className="col-12">
            <button type="submit" 
              style={{ 'background': props.data.color }}
              className="btn btn-lg btn-block mt-3"
              disabled={isdisabled}
            >
              {sending ? <Spinner /> : <Send />}
              {sending ? plHolder.sent : plHolder.button}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withTranslation('common')(ContactForm);
