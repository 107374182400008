import React from "react";
import Action from "./Action";
import FeaturesList from "./FeaturesList";
import TextSection from "./TextSection";
import { withTranslation, useTranslation } from "react-i18next";
import ImageSection from "./ImageSection";

const Home = ({ reverse, id, data, action }) => {
  const [t] = useTranslation("common");
  return (
    <div className="spiderProduct-section" id={id}>
      <div className={`row ${reverse ? "reverse" : ""}`}>
        <div className="spiderProduct-section--data col-12 col-sm-12 col-md-7">
          <div
            className="spiderProduct-section--header"
            style={{ width: "100%" }}
          >
            <h3>{t(`${data}.subtitle`)}</h3>
            <h1>{t(`${data}.title`)}</h1>
          </div>
          <TextSection data={data} direction={"left"} />
          <div className="spiderProduct-section--footer">
            <h3>{t(`${data}.features-title`)}</h3>
            <FeaturesList data={data} />
            {action && <Action data={data} />}
          </div>
        </div>
        <ImageSection data={data} />
      </div>
    </div>
  );
};

export default withTranslation("common")(Home);
