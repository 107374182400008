import React from "react";
import Action from "./Action";
import TextSection from "./TextSection";
import { withTranslation, useTranslation } from "react-i18next";
import Carousel from "../../ScreenshotSection/Carousel";


const Home = ({ reverse, id, data, action }) => {
  const [t] = useTranslation("common");
  return (
    <div className="spiderProduct-section" id={id}>
      <div className={`row ${reverse ? "reverse" : ""}`}>
        <div className="spiderProduct-section--data col-12 col-sm-12 col-md-8">
          <div
            style={{ width: "100%" }}
          >
            <h3 className="feeder-manager-home-subtitle">{t(`${data}.subtitle`)}</h3>
            <h1 className="feeder-manager-home-title">{t(`${data}.title`)}</h1>
          </div>
          <TextSection data={data} direction={"left"} />
          <div className="spiderProduct-section--footer">
            {action && <Action data={data} />}
          </div>
        </div>
        <div className="app-screenshots d-flex justify-content-center --img col-12 col-sm-12 col-md-4">
          <Carousel flag="feeder" data={t(`${data}.img`, { returnObjects: true })} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(Home);
