import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

const Header = function PlatformElementsSection() {
  const [t] = useTranslation('common');
   
  return (
    <section id="discover-section">
      {/* Discover Thumb */}
      {/*
      <div className="section-heading text-center pt-5">
        <h2>{t('spider.title')}</h2>
      </div>*/}
      <div className="container">
        <div className="row justify-content-center"> 
        {
          t('spider-acoustic.platform.data',{ returnObjects: true }).map((element,idx) =>
          <div className="col-md-4 col-lg-4 " key={idx}>
            <div className='element-content-container'>
              <div className="element-content-title">
                {t(`${element.title}`)}
              </div>
              <div className="element-content-subtitle">
                {t(`${element.subtitle}`)}
              </div>
              <p className="element-content align-self-center">
                {t(`${element.text}`)}
              </p>
            </div>
            <div className='element-img-container'>
              {/* Discover Thumb */}
              <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0 d-none d-md-block">
                <img
                  className="vector-ia"
                  width={325}
                  height={325}
                  src={`${element.img}`}
                  alt="dispositivo spider para alimentación inteligente de camarón"
                />
              </div>
              <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0 d-block d-md-none">
                <img
                  className="vector-ia-mobile"
                  width={325}
                  height={325}
                  src={`${element.img}`}
                  alt="dispositivo spider para alimentación inteligente de camarón"
                />
              </div>
            </div>
          </div>
          )
          }
        </div>
      </div>

    </section>
  );
};

export default withTranslation('common')(Header);
