import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

const FeatureSection = function Comp() {
  const [t] = useTranslation('common');

  return (
    <section id="SpiderAcousticGains" className="section features-area bg-overlay overflow-hidden ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12 col-lg-12">
            {/* Section Heading */}
            <div className="section-heading-white text-center">
              <h2 className='spider-acoustic-gains-section-title-white'>{t('spider-acoustic.gains.title')}</h2>
              <p className="d-none d-sm-block mt-4">{t('spider-acoustic.gains.text')}</p>
              <p className="d-block d-sm-none mt-4">{t('spider-acoustic.gains.text')}</p>
            </div>
          </div>
        </div>
        <div className="row p-2">
          { t('spider-acoustic.gains.data',{ returnObjects: true }).map((item_array, idx_array) => {
            return (
              <div key={`gain_${idx_array}`} className="col-12 col-md-4">
                {/* Icon Box */}
                <div className="icon-box text-center p-3">
                  {/* Featured Icon */}
                  <div className='d-flex align-items-center justify-content-center'>
                    <div
                      className="svg-feature d-flex justify-content-center"
                      style={{
                        background: `url('${item_array.svg}') center no-repeat`,
                      }}
                    >
                    </div>
                  </div>
                  {/* Icon Text */}
                  <div className="icon-text">
                    <h3 className="spider-acoustic-gains-options-title-white mb-2"> {t(`${item_array.title}`)} </h3>
                    <p className='spider-acoustic-gains-options-content-white'>{t(`${item_array.text}`)}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default withTranslation('common')(FeatureSection);
