import React from 'react';

import { withTranslation, useTranslation } from 'react-i18next';

const Header = function HomeSection() {
    const [t] = useTranslation('common');
    return (
        <section id="home" 
        className="section welcome-area overflow-hidden d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 d-flex text-right">
                    </div>
                    <div className="col-md-8 d-flex justify-content-end text-right">
                        <h1 className="home-title">
                            <mark className='home-mark'>{t("bd_home.title")}</mark>
                        </h1>
                    </div>
                    <div className="col-md-12 d-flex justify-content-end text-right">
                        <div className='column'>
                            <h2 className="home-subtitle">
                                <mark className='home-mark2'>
                                    {t("bd_home.subtitle")}
                                </mark>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-bottom">
                <svg viewBox="0 0 1920 310" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="svg replaced-svg">
                    <defs />
                    <g id="sApp-Landing-Page" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <g id="sApp-v1.0" transform="translate(0.000000, -554.000000)" fill="#FFFFFF">
                        <path d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395" id="sApp-v1.0" />
                    </g>
                    </g>
                </svg>
            </div>
        </section>
    );
}
export default withTranslation('common')(Header);