import React from "react";

const ScrollToTopBubble = () => {
  return (
    <div id="scrollUp" title="Scroll To Top">
      <i className="fas fa-arrow-up" />
    </div>
  );
};

export { ScrollToTopBubble };
