import React from "react";
import { withTranslation, useTranslation } from "react-i18next";

const ImageSection = ({ data }) => {
  const [t] = useTranslation("common");
  return (
    <div className="spiderProduct-section--img col-12 col-sm-12 col-md-12">
      <img style={{
        height: "auto",
        width: 'auto',
        padding: "2rem",
      }} src={t(`${data}.img`)} alt={t(`${data}.img-alt`)} />
    </div>
  );
};

export default withTranslation("common")(ImageSection);