import React from "react";
import Carousel from "../../ScreenshotSection/Carousel";
import { withTranslation, useTranslation } from "react-i18next";

const DefaultScreen = ({ reverse, data, id }) => {
  const [t] = useTranslation("common");
  return (
    <div className="spiderProduct-section" id={id}>
      <div className={`row ${reverse ? "reverse" : ""} px-2`}>

        <div className="spiderProduct-section--data col-12 col-sm-12 col-md-12">
          <div className="spiderProduct-section--header section-heading text-center">
            <h2 className="text-capitalize">{t(`${data}.data.heading`)}</h2>
            <p className="d-flex justify-content-center mt-4">
              {t(`${data}.data.headingText`)}
            </p>
            <p className="d-flex justify-content-center mt-4">
              {t(`${data}.data.headingTextTwo`)}
            </p>
            <p className="d-flex justify-content-center mt-4">
              {t(`${data}.data.headingTextThree`)}
            </p>
            <p className="d-flex justify-content-center mt-4">
              {t(`${data}.data.headingTextFour`)}
            </p>
          </div>
          {/* <TextSection data={`${data}`} textAlign={"center"} /> */}
        </div>
        <div className="app-screenshots spiderProduct-section--img col-12 col-sm-12 col-md-12">
          <Carousel flag="cr" data={t(`${data}.img`, { returnObjects: true })} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(DefaultScreen);
