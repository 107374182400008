import React from 'react';
import ImageSection from "../ImageSection";

import { withTranslation, useTranslation } from 'react-i18next';

const Header = function Home({ data }) {
  const [t] = useTranslation('common');

  return (
    <section
      id="xAgro-software"
      className="section overflow-hidden d-flex flex-column align-items-center"
    >
      <div className="container d-flex flex-column h-100">
        <div className="row h-100">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
            <div className='mt-3'>
              <h1 className='xAgro-software-title' >{t(`${data}.title`)}</h1>
            </div>
            <div className="mb-3">
              <div className="xAgro-software-text">{t(`${data}.subtitle`)}</div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <div className="position-relative" 
            style={{ padding: '2rem' ,maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}>
              <img src={t(`${data}.img`)} alt="xagro dashboard"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation('common')(Header);