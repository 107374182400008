import React from 'react';
import ImageSection from "../ImageSection";

import { withTranslation, useTranslation } from 'react-i18next';

const Header = function Home({ data }) {
  const [t] = useTranslation('common');
  const title = t(`${data}.title`, { returnObjects: true });
  const text =  t(`${data}.text`, { returnObjects: true });

  return (
    <section
      id="xAgroNetwork"
      className="section overflow-hidden d-flex align-items-center"
    >
      <div className="xAgro-network-container">
        <div className='row' style={{height:'100%',margin:'0px'}}>
          <div id="xAgroNetworkImage" className="col-12 col-md-6 d-flex align-items-center justify-content-end flex-column">
          </div>
          <div className="xAgro-network-content col-12 col-md-6 d-flex align-items-center justify-content-center flex-column">
            <div className="d-flex justify-content-center text-center mx-2" 
              style={{ width: "100%" }}>
              <h1 className="xAgro-network-title">{title}</h1>
            </div>
            <div className="d-flex justify-content-center text-center mt-2 mx-2" 
              style={{ width: "100%" }}>
              <h1 className="xAgro-network-text">{text}</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation('common')(Header);