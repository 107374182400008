import React from "react";
import { withTranslation, useTranslation } from "react-i18next";

const TextSection = ({ data, textAlign }) => {
  const [t] = useTranslation("common");
  return (
    <div
      className="spiderProduct-section--main"
      style={{
        textAlign: textAlign,
        padding: "0",
        marginTop : "1rem"
      }}
    >
      {t(`${data}.text`, { returnObjects: true }).map((text, index) => {
        if (text.type === "strong") {
          return (
            <strong
              style={{
                display: "inline",
              }}
              key={`${data}.text-list_${index}`}
            >
              {text.content}{" "}
            </strong>
          );
        } else if (text.type === "normal") {
          return (
            <p
              style={{
                display: "inline",
              }}
              key={`${data}.text-list_${index}`}
            >
              {text.content}{" "}
            </p>
          );
        } else if (text.type === "underline") {
          return (
            <p
              style={{
                display: "inline",
                textDecoration: "underline",
              }}
              key={`${data}.text-list_${index}`}
            >
              {text.content}{" "}
            </p>
          );
        } else {
          return <p key={`${data}.text-list_${index}`}
          >{text.content}</p>;
        }
      })}
    </div>
  );
};

export default withTranslation("common")(TextSection);
