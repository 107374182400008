import React from 'react';
import ContactForm from './ContactForm';
import { withTranslation, useTranslation } from 'react-i18next';

const initData = {
  heading: 'Manténgase al Tanto',
  content:
    'Puedes contactarnos por alguno de estos medios o envíanos un correo con el formulario a la derecha.',
  iconList: [
    {
      id: 1,
      iconClass: 'fas fa-home',
      href: 'https://www.google.com/maps/@-2.113946,-79.909676,21z',
      text: 'Guayaquil',
    },
    {
      id: 2,
      iconClass: 'fas fa-phone-alt',
      href: 'https://api.whatsapp.com/send?phone=593967113050',
      text: '+593 95-9513557',
    },
    {
      id: 3,
      iconClass: 'fas fa-envelope',
      href: 'mailto:ventas@biodynamics.tech',
      text: 'ventas@biodynamics.tech',
    },
  ],
};

const ContactSection = function Comp(parameters) {
  const [t] = useTranslation('common');
  const data = {
    name: t('contact.form.name'),
    email: t('contact.form.email'),
    subject: t('contact.form.subject'),
    msg: t('contact.form.msg'),
    sent: t('contact.form.sent'),
    button: t('contact.form.button'),
    color: parameters.color
  };

  return (
    <section id="contact" className="contact-area bg-gray ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-6">
            {/* Section Heading */}
            <div className="section-heading text-center">
              <h2 className="section-title text-capitalize">{t('contact.title')}</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 pt-4 pt-md-0 mb-3">
            <ContactForm data={data}/>
          </div>
          <div className="col-12 col-md-12">
            {/* Contact Us */}
            <div className="contact-us">
              <p className="d-flex justify-content-center mb-3">{t('contact.text')}</p>
              <ul className="row contact-us-list" >
                {initData.iconList.map((item, idx) => {
                  return (
                    <li key={`ci_${idx}`} className="col-12 col-md-4 py-2">
                      <a
                        className="media d-flex flex-column justify-content-center align-items-center"
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="social-icon mb-3" style={{ background: parameters.color }}>
                          <i className={item.iconClass} />
                        </div>
                        <span className="media-body align-self-center">
                          {item.iconClass.includes('fa-home')
                            ? t(`contact.address`)
                            : item.text}
                        </span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation('common')(ContactSection);
