import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

const Work = function Comp() {
  const [t] = useTranslation('common');

  return (
    <section id="SpiderWork" className="section work-area bg-overlay overflow-hidden mt-5 pt-5 mb-5 pb-5" >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12">
            {/* Work Content */}
            <div className="section-heading-white text-center pb-5">
              <h2 className="spider-work-title-white">{t('spider-acoustic.work.title')}</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
            { t('spider-acoustic.work.data',{ returnObjects: true }).map((item_array, idx_array) => {
            return (
              <div key={`work_${idx_array}`} className="col-12 col-md-3 mt-4 mb-4">
                <div className="single-work text-center p-0">
                  {/* Work Icon */}
                  <div>
                    <img className="avatar-md" src={item_array.svg} alt="" />
                  </div>
                  <h3 className="spider-work-options-title-white py-3">
                    {t(`${item_array.title}`)}
                  </h3>
                  <p className="spider-work-options-content-white pl-3 pr-3">
                    {t(`${item_array.text}`)}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default withTranslation('common')(Work);
