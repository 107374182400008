import React from 'react';

import { withTranslation, useTranslation } from 'react-i18next';

const Header = function Home() {
  const [t] = useTranslation('common');
  return (
    <section
      id="satty-Image-Background"
      className="section overflow-hidden d-flex align-items-center mt-5 pt-5"
    >
      <div className="satty-image-background-container">
        <div style={{ height:"100%"}} className="row d-flex align-items-start justify-content-end">
          <div id="satty-image-background-img-sounder" className="col-12 col-md-6 col-lg-6">
          </div>
          <div style={{ height:"100%"}}
            className="col-12 col-md-6 col-lg-6 flex-column d-flex justify-content-center align-items-center">
            <div className='text-right'>
              <h1 className="satty-image-background-title">
                {t("satty.image-section.title")}
              </h1>
            </div>
            <div className="satty-image-background-text">
              {t("satty.image-section.text")}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation('common')(Header);