import GA4React from 'ga-4-react';

let ga4react;

export async function init() {
  if (!GA4React.isInitialized() && process.browser) {
    ga4react = new GA4React("G-0FDEFXVL5D");
    try {
      await ga4react.initialize().catch(err => console.error(err));
    } catch (error) {
      console.error(error);
    }
  }
}

export function logPageView() {
  console.log("logPageView");
  ga4react.pageview(window.location.pathname + window.location.search);
}

export function logEvent(action, label, category) {
  ga4react.event(action, label, category);
}