import React, { useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const Header = function CompHeader(props) {
  const [t, i18n] = useTranslation("common");
  
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      i18n.changeLanguage("es");
    }
  }, [i18n]);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
    console.log("Idioma seleccionado:", language);
  };

  return (
    <header className="navbar justify-content-center navbar-sticky navbar-expand-lg navbar-dark navbar-sticky-moved-up navbar-sticky-transitioned navbar-sticky-on">
      <div className="d-flex justify-content-center container position-relative">
        <a className="navbar-brand" href="/">
          {/*onClick={() => logEvent('Clicked Nav', 'Click in Logo', 'Nav header image')} */}
          <img
            className="navbar-brand-sticky"
            src={props.imageData}
            alt="sticky brand-logo"
          />
        </a>
        <button
          className="navbar-toggler d-lg-none navbar-collapse-custom"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-nav"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="ml-5 collapse navbar-collapse justify-content-end" id="navbar-nav">
          <nav>
            <ul className="navbar-nav" id="navbar-nav">
              {/* onClick={() => logEvent('Clicked Nav', 'Click in Home option', 'Nav header option')} */}
              <li className="nav-item">
                <a className="nav-link scroll" href="/">
                  {t("nav.home")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scroll" href="#contact">
                  {t("nav.contact")}
                </a>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#!"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {t("nav.products.name")}
                </Link>
                <ul
                  className="dropdown-menu pl-2"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {t("nav.products.list", {
                    returnObjects: true,
                  }).map((product, index) => {
                    return (
                      <li key={`nav.products_${index}`}>
                        <Link className="dropdown-item scroll" to={product.link}>
                          {product.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
              {/* 
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#!"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {t("nav.lang")}
                </a>
                <ul
                  className="dropdown-menu pl-2"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a
                      className="dropdown-item scroll"
                      href="#es"
                      onClick={() => handleLanguageChange("es")}
                    >
                      Español
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item scroll"
                      href="#en"
                      onClick={() => handleLanguageChange("en")}
                    >
                      English
                    </a>
                  </li>
                </ul>
              </li>*/}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default withTranslation("common")(Header);
