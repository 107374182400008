import React, { Suspense } from "react";
import { logPageView } from "./ga";
// import { useGA4React } from "ga-4-react";

// importing MyRouts where we located all of our theme
import MyRouts from "./routers/routes";

function App() {
  //logPageView()
  return (
    <Suspense fallback="loading">
      <div className="main-container">
        <MyRouts />
      </div>
    </Suspense>
  );
}
export default App;
