import React from "react";

import { withTranslation } from "react-i18next";

import HomeSection from "../components/Home/Home";
import HomeFeaturesSection from '../components/Home/HomeFeaturesSection';
import Products from "../components/Home/Products";
import BrandingSection from "../components/Home/Branding";
import ContactSection from "../components/ContactSection/Contact";

const ThemeSix = function ThemeSix() {
  return (
    <div className="homepage-1">
      <div className="main">
        <HomeSection />
        <HomeFeaturesSection />
        <Products />
        <BrandingSection />
        <ContactSection color={"#df2a2d"} />
      </div>
    </div>
  );
};

export default withTranslation("common")(ThemeSix);
