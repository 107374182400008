import React from "react";

import "./css/Styles.css";
import "./css/Home.css";
import "./css/Features.css";

import Home from "./components/Home";
import Components from "./components/Components";
import Features from "./components/Features";
import DefaultScreen from "./components/DefaultScreen";
import { withTranslation } from "react-i18next";
import CarouselScreen from "./components/CarouselScreen";

const data = `aqua-feeder`;

const SpiderFeeder = () => {
  return (
    <div className="mt-5 pt-5 spiderProduct-Screen" id="spiderFeeder">
      <Home id={"Feeder"} reverse={false}  data={`${data}.home`} action={false} />
      <Components data={`${data}.components`} id={"Components"} />
      <CarouselScreen
        reverse={true}
        data={`${data}.carousel-section`}
        id={"screenshots"}
      />
      <Features data={`${data}.features`} />
      <DefaultScreen
        reverse={false}
        data={`${data}.feed-crops`}
        id={"FeedCrop"}
      />
      <DefaultScreen reverse={true} data={`${data}.tech`} id={"Tech"} />
      <DefaultScreen
        reverse={false}
        data={`${data}.precision`}
        id={"Precision"}
      />
    </div>
  );
};

export default withTranslation("common")(SpiderFeeder);