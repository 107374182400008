import React from 'react';
import ImageSection from "../ImageSection";

import { withTranslation, useTranslation } from 'react-i18next';

const Header = function Home({ data }) {
  const [t] = useTranslation('common');
  const title = t(`${data}.title`, { returnObjects: true });
  const subtitle =  t(`${data}.subtitle`, { returnObjects: true });
  return (
    <section
      id="xAgroHome"
      className="section overflow-hidden d-flex align-items-center"
    >
      <div className="xAgro-home-container">
        <div className='row' style={{height:'100%',margin:'0px'}}>
          <div className="col-12 col-md-8 d-flex justify-content-center flex-column">
            <div className="d-flex" 
              style={{ width: "100%" }}>
              <h1 className="xAgro-home-title"> <mark className='xAgro-home-mark'>{title}</mark></h1>
            </div>
            <div className="d-flex mt-2" 
              style={{ width: '100%' }}>
              <div>
                <h2 className="xAgro-home-subtitle">{subtitle}</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 d-flex align-items-center justify-content-center">
            <div className='pt-1'>
              <ImageSection data={data} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation('common')(Header);