import React from "react";

import "./css/Styles.css";
import "./css/Home.css";
import "./css/Features.css";
import "./css/xAgro.css";


import FooterSection from "./components/xAgro/FooterSection";
import HomeBackground from "./components/xAgro/HomeBackground";
import FeaturesPlatform from "./components/xAgro/FeaturesPlatform";
import Resume from "./components/xAgro/Resume";
import Network from "./components/xAgro/Network";

import Features from "./components/Features";
import DefaultScreen from "./components/xAgro/xAgroScreen";
import DigitalTools from "./components/xAgro/DigitalTools";
import Software from "./components/xAgro/Software";
import ContactSection from "../ContactSection/Contact";


import { withTranslation } from "react-i18next";

const data = `xAgro`;

const xAgro = () => {
  return (
    <div className="spiderProduct-Screen main" id="spiderMonitor">
      <HomeBackground data={`${data}.home`}/>
      <FeaturesPlatform data={`${data}.features-app`} />
      <Resume data={`${data}.resume`} />
      <Network data={`${data}.network`}/>
      <Features data={`${data}.features`} />
      <DefaultScreen
        reverse={false}
        data={`${data}.weather`}
        id={"xAgroWeather"}/>
      <DefaultScreen
        reverse={true}
        data={`${data}.agro`}
        id={"xAgroWeatherPro"}
      />
      <DefaultScreen
        reverse={false}
        data={`${data}.gateway`}
        id={"xAgroGateway"}
      />
      <DigitalTools
        reverse={true}
        data={`${data}.tools`}
        id={"xAgroTools"}
      /> 
      <Software data={`${data}.software`}/>
      {/*<Features data={`${data}.benefits`} />*/}
      <FooterSection />
      <ContactSection color={"#2a3890"} />
    </div>
  );
};

export default withTranslation("common")(xAgro);
