import React from 'react';

import { withTranslation, useTranslation } from 'react-i18next';

const Header = function BDProducts() {
  const [t] = useTranslation('common');
  return (
    <section id="products" className="section welcome-area overflow-hidden d-flex align-items-center mt-5 pt-5" >
      <div className="container">
        <div className="column align-items-center justify-content-center">
          <div className="col-md-12">
            <div className="products-subtitle">{t("products.text")}</div>
          </div>
          <div className="col-md-12">
            <div className="products-title">{t("products.title")}</div>
          </div>
          <div className='col-12 col-md-12 mt-2 products-btn-container'>
            <div className='row' style={{'gap':'1rem'}}>
              <div className='col-12 col-md-4 my-2'>
                <a href="/spider" className="products-btn">{t("products.spider")}</a>
              </div>
              <div className='col-12 col-md-3 my-2'>
                <a href="/spider/monitor" className="products-btn btn-bordered">{t("products.monitor")}</a>
              </div>
              <div className='col-12 col-md-12 my-2'>
                <a href="/feeder" className="products-btn btn-bordered">{t("products.aqua_feeder")}</a>
              </div>
              <div className='col-12 col-md-6 my-2'>
                <a href="/satty" className="products-btn btn-bordered">{t("products.satty")}</a>
              </div>
              <div className='col-12 col-md-6 my-2'>
                <a href="/xAgro" className="products-btn btn-bordered">{t("products.xagro")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation('common')(Header);
