import React from "react";
import TextSection from "../TextSection";
import ImageSection from "../ImageSection";
import { withTranslation, useTranslation } from "react-i18next";

const DefaultScreen = ({ reverse, data, id }) => {
  const [t] = useTranslation("common");
  const textAlign = reverse ? "right" : "left";
  const containerStyle = {
    textAlign,
    margin: '0px'
  };

  return (
    <div className="spiderProduct-section" id={id}>
      <div className={`row ${reverse ? "reverse" : ""}`} style={{ width: '100%'}}>
        <div className="col-12 col-sm-12 col-md-6" style={{display: 'flex', alignItems:'center'}}>
          <div style={containerStyle}>
            <div className="spiderProduct-section--header">
              <h2 style={{ textAlign , color: "#8b03cbff" }}>{t(`${data}.title`)}</h2>
              <h3>{t(`${data}.subtitle`)}</h3>
            </div>
            <TextSection data={data} textAlign={textAlign} />
          </div>
        </div>
        <div className="d-flex justify-content-center col-12 col-sm-12 col-md-6">
          <ImageSection data={data} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(DefaultScreen);