import React from "react";
import { withTranslation, useTranslation } from "react-i18next";

const Header = function FeaturesSection() {
  const [t] = useTranslation("common");
  return (
    <section
      id="feature-section"
      className="section discover-area bg-gray overflow-hidden mt-5 pt-5 mb-5 pb-5"
    >
      <div className="section-heading text-center pt-5">
        <h2 className="spider-feactures-section-title">{t("spider-acoustic.features.title")}</h2>
      </div>
      <div className="spider-features-container">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="spider-features-content col-12 col-md-12">
              {t("spider-acoustic.features.text")}
            </div>
            <div className="description-list-features col-12 col-md-12">
              <div className="row">
                {t("spider-acoustic.features.data", { returnObjects: true }).map(
                  (feature, idx) => (
                    <div
                      key={`feature_${idx}`}
                      className="description-feature col-12 col-md-4 d-flex flex-row justify-content-center align-items-center"
                    >
                      <div className="description-item-img col-3 col-md-2 col-lg-3 d-flex justify-content-center align-items-center">
                        <div
                          className="svg-feature"
                          style={{
                            background: `url('${feature.img}') center no-repeat`,
                          }}
                        ></div>
                      </div>
                      <div className="description-item-container d-flex flex-column col-10 col-md-10 col-lg-9">
                        <div className="spider-features-description-item-title">
                          {t(`${feature.title}`)}
                        </div>
                        <div className="spider-features-description-item-content">
                          {t(`${feature.content}`)}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation("common")(Header);
