import React from "react";
import TextSection2 from "./TextSection2";
import { withTranslation, useTranslation } from "react-i18next";

const FeaturesList2 = ({ data }) => {
  const [t] = useTranslation("common");
  return (
    <div className="spiderProduct-section spiderProduct-section--list">
      <div className="row">
        {t(`${data}.features-list`, {
          returnObjects: true,
        }).map((feature, index1) => {
          return (
            <div
              key={`${data}.features-list_${index1}`}
              className="spiderProduct-section--data col-12 col-sm-12 col-md-4"
            >
              <div className="spiderProduct-section--header">
                <h3>{feature["subtitle"]}</h3>
              </div>
              <div className="spiderProduct-section--img">
                <img src={feature["img"]} alt={feature["img-alt"]} />
              </div>
              <TextSection2 data={data} index1={index1} feature={feature} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withTranslation("common")(FeaturesList2);
