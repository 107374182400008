import React from "react";
import { withTranslation, useTranslation } from "react-i18next";

const Action = ({ data }) => {
  const [t] = useTranslation("common");
  return (
    <div className="spiderProduct-section--container-action">
      <div className="spiderProduct-section--action">
        <a href={t(`${data}.action-button`)}>{t(`${data}.action-button`)}</a>
      </div>
    </div>
  );
};

export default withTranslation("common")(Action);
